/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/ducati-theme';
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

$ducati-red: #cc0000;
$ducati-light-red: rgba(204, 0, 0, 0.2);
$ducati-success: #45b578;
$ducati-dark-grey: #666666;
$ducati-light-grey-1: #bbbcbc;
$ducati-light-grey-2: #ebebeb;

.ag-theme-alpine {
    @include ag-theme-alpine(
        (
            header-foreground-color: #ffffff,
            header-background-color: $ducati-red,
            font-size: 10px,
        )
    );
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* Material customizations */
.mat-button,
.mat-flat-button,
.mat-raised-button,
.mat-stroked-button {
    border-radius: 200px !important;
}

.mat-stroked-button {
    border-color: $ducati-red !important;
}

.mat-form-field.-small {
    & .mat-form-field-wrapper {
        padding-bottom: 0.8em;
    }
}

.mat-tab-link,
.mat-tab-label {
    height: 35px !important;
    font-size: 10px !important;
}

.mat-tab-link.mat-tab-label-active,
.mat-tab-label.mat-tab-label-active {
    opacity: 1;
}

/* Ag Grid customizations */
.ag-floating-bottom {
    border-top: 2px solid $ducati-red !important;
}

.ppm-grey {
    color: #ffffff !important;
    background-color: $ducati-light-grey-1 !important;
}

.ppm-light-grey {
    background-color: $ducati-light-grey-2 !important;
}

.ppm-bold {
    font-weight: 700;
}

.ppm-positive {
    color: $ducati-success !important;
}

.ppm-negative {
    color: $ducati-red !important;
}

.ppm-grid-cell-highlight {
    background-color: $ducati-light-grey-2;
}

.ppm-grid-cell-primary {
    background-color: $ducati-light-red;
}

.ppm-grid-row-totals {
    color: #000000 !important;
    font-weight: 700 !important;
}

.ppm-grid-column-totals {
    text-align: right;
    font-weight: 700;
    background-color: $ducati-light-red !important;
}

.ppm-ag-readonly {
    .ag-header {
        background-color: $ducati-dark-grey;
    }

    .ppm-grid-row-totals {
        color: $ducati-dark-grey !important;
    }

    .ag-floating-bottom {
        border-top: 2px solid $ducati-dark-grey !important;
    }

    .ppm-grid-column-totals {
        background-color: $ducati-light-grey-2 !important;
    }
}
